@mixin bgcover(){
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: auto;
    max-height: none;
    max-width: none;
    min-height: 100%;
    min-width: 100%;
    transform: translate(-50%,-50%);
    object-fit: cover;
}