

body{
    margin: 0;

    #wepay4u{
        margin: 0 auto;
        font-family: 'Roboto', sans-serif;
        font-size: 15px;
        color: #0e0e0e;
        overflow: hidden;

        position: relative;

        *{
            box-sizing: border-box;
            &:focus{
                outline: none;
            }
        }

        header{
            width: 100%;
            background-image: linear-gradient(to right, #072954 0%, #131229);
            overflow: hidden;
            position: relative;
            margin-bottom: 3rem;
            .w{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                height: 74px;
                margin: 0 auto;
                width: calc( 100% - 4rem);
            }
            img{
                display: block;
            }
            .bg{
                @include bgcover();
                z-index: 1;
            }
            .logo{
                width: 100px;
                z-index: 2;
                position: relative;
                margin: 0;
            }
        }

        ul{
            list-style-type: none;
            padding: 0;
        }

        .success{
            padding: .5rem 1rem;
            border: 1px solid transparent;
            border-radius: .25rem;
            color: #155724;
            background-color: #d4edda;
            border-color: #c3e6cb;
            width: 100%;
        }

        .error{
            padding: .5rem 1rem;
            border: 1px solid transparent;
            border-radius: .25rem;
            color: #721c24;
            background-color: #f8d7da;
            border-color: #f5c6cb;
        }

        .title{
            margin: 0;
            color: $color-orange;
            font-size: 2rem;
            text-align: center;
            line-height: .97;
        }

        .wrap{
            max-width: 1138px;
            max-width: 100%;
            margin: 0 auto;
        }

        .list-payment-data{
            background-color: #f0f2f7;
            padding: 1.75rem;
            width: calc( 100% - 4rem );
            margin: 2rem auto 1.75rem auto;
            border-radius: 4px;
            span{
                color: #7e7f81;
                font-size: .85rem;
                line-height: 1.5;
                display: block;
                font-weight: 700;
            }
            strong{
                font-size: 1.5rem;
                line-height: 1.5;
            }
            li{
                display: block;
                border-bottom: 1px solid #c1cad6;
                text-align: center;
                padding-bottom: .75rem;
                margin-bottom: .75rem;
                &:last-child{
                    padding-bottom: 0;
                    margin-bottom: 0;
                    border-bottom: none;
                }
            }
        }

        .time-data{
            text-align: center;
            margin: 0 auto 1.75rem auto;
            svg, p{
                margin: 0 auto;
            }
            b, span{
                display: block;
                margin: 0 auto .12rem auto;
            }
            b{
                font-size: 1.05rem;
            }
            span{
                font-size: .92rem;
            }
        }

        .confirm-data{
            text-align: center;
            svg{
                margin: 2rem auto .5rem auto;
            }
        }

        button{
            appearance: none;
            border: none;
            background-color: transparent;
            cursor: pointer;
        }

        .tabs-head{
            width: calc( 100% - 4rem);
            margin: 0 auto;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            li{
                width: calc(50% - 1rem);
                button{
                    background-color: #90929e;
                    color: #FFFFFF;
                    padding: 1.12rem;
                    border-radius: 5px 5px 0 0;
                    width: 100%;
                    font-size: 1rem;
                    line-height: 1.25;
                }
                &.active{
                    button{
                        background-color: #ff9100;
                        color: #FFF;
                    }
                }
            }
        }

        .tabs-container{
            background-color: #f0f2f7;
            color: #3b3b3b;
            h3,p{
                margin: 0 auto;
                text-align: center;
                padding: 1.5rem 0;
                font-weight: 600;
            }
            h3{
                font-size: 23px;
            }
            p{
                margin: 0 auto;
                text-align: center;
                padding: 1.5rem 0;
                font-size: 18px;

            }
            .subtitle{
                padding: 0 0 1.5rem 0;
                &:before{
                    content: '';
                    width: 2px;
                    height: 30px;
                    background-color: #c7c7c7;
                    display: block;
                    margin: 0 auto .5rem auto;
                }
            }
            .type-pay{
                font-size: 16px;
                padding: 0;
                margin: 0 auto 2rem auto;
            }
        }

        .wrap-slider{
            position: relative;
            max-width: 960px;
            margin: 0 auto;
            min-height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .slider{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0;
            width: 80%;
            margin: 0 auto;
            .slide-item{
                width: 25%;
                transition: all .3s ease;
                z-index: 1;
                text-align: center;
                border: 2px solid transparent;
                opacity: .4;
                button{
                    margin: 0;
                    padding: 0;
                }
                img{
                    width: 100%;
                    display: block;
                }
                &.active{
                    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
                    border: 3px solid #ffba00;
                    width: 40%;
                    opacity: 1;
                }
                &:nth-child(1){
                    content: '';
                }
                &:nth-child(2){
                    content: '';

                }
                &:nth-child(3){
                    content: '';

                }
                &:nth-child(4){
                    content: '';

                }
                &:nth-child(5){
                    content: '';

                }
            }
            &.s2{
                .slide-item{
                    &:nth-child(3){
                        border: 2px solid #ffba00;
                    }
                }
            }
        }

        .btn{
            margin: 0 auto;
            display: block;
            text-align: center;
            color: #FFF;
            background-color: #ff9100;
            padding:  1.12rem .75rem;
            width: calc( 100% - 4rem);
            margin-top: 2rem;
            border-radius: 6px;
            font-size: 1rem;
            margin-bottom: 4rem;
            cursor: pointer;
            line-height: 1;
            text-decoration: none;
            &:active, &:focus, &:hover{
                text-decoration: none;
            }
        }

        .btn-arrow-left, .btn-arrow-right{
            position: absolute;
            width: 50px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 3;
            font-size: 0px;
            transition: all .3s ease;
            &:after, &:before{
                content: '';
                background-color: #ffba00;
                display: block;
                width: 3px;
                height: 28px;
            }
        }

        .btn-arrow-left{
            left: 0;
            &:before{
                transform: translateX(.5rem) translateY(2px) rotate(30deg)
            }
            &:after{
                transform: translateX(.5rem) translateY(-2px) rotate(-30deg)
            }
            &:hover{
                transform: translateY(-50%) translateX(-.25rem);
            }
        }

        .btn-arrow-right{
            right: 0;
            &:before{
                transform: translateX(1.5rem) translateY(2px) rotate(-30deg)
            }
            &:after{
                transform: translateX(1.5rem) translateY(-2px) rotate(30deg)
            }
            &:hover{
                transform: translateY(-50%) translateX(.25rem);
            }
        }

        .list-steps-0{
            margin: 0 auto 2rem auto;
            width: calc( 100% - 4rem);
            .separador{
              width: 60%;
              border: 1px solid rgb(206, 206, 206);
              margin:auto;
            }

            li{
                 background: white;
                 border-radius:30px;
                 padding: 3%;
                 padding-bottom: 30px;
                svg,img{
                    margin-bottom: 20px;
                    width: 60px;
                    margin-top: 0px;
                    margin: 0 auto;
                    display: block;
                }
                p{
                    margin: 0 auto;
                    text-align: center;
                    padding-bottom: 10px;
                    font-weight: 300;
                    font-size: 15px;
                    max-width: 250px;
                }
                form{
                    width: 70%;
                    margin:auto;
                    margin-top: 2rem;
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                    position: relative;
                    div{
                      width: 100%;
                      display: flex;
                    }
                    button{
                      display: flex;
                      color: #63615f;
                      background: #e8e5dd;
                      height: 40px;
                    }
                    input{
                        width: 50%;
                        border: 1px solid #ced4da;
                        appearance: none;
                        background: #fff;
                        height: 40px;
                        flex-grow: 1;
                        font-size: 15px;
                        font-weight: 300;
                        width: 50%;
                        &:hover, &:active, &:visited{
                            box-shadow: 0 0 5px #20c997;
                        }
                    }
                }
            }
            &.paymentAgent{
                li{
                    width: 100%;
                }
                .type-pay{
                    padding: 2rem 0 0 0;
                    border-top: 1px solid #c7c7c7;
                }
            }
        }


        .list-steps-1{
          margin: 0 auto 2rem auto;
          width: calc( 100% - 4rem);
          li{
              svg,img{
                  width: 94px;
                  margin: 0 auto;
                  display: block;
              }
              p{
                  font-weight: 300;
                  font-size: 15px;
                  text-align: left;
                  max-width: 250px;
              }
          }
          &.paymentAgent{
              li{
                  width: 100%;
              }
              .type-pay{
                  padding: 2rem 0 0 0;
                  border-top: 1px solid #c7c7c7;
              }
          }
        }



        .info{
            width: calc( 100% - 4rem);
            padding-bottom: 2rem;
            margin: 0 auto;
            a,button{
                font-weight: 600;
                font-size: 18px;
                width: 300px;
                overflow-wrap: break-word;
            }
            div{
              width: 50%;
              // height: 100px;
              float: left;
            }
            p{
                font-size: 15px;
                font-weight: 300;
                text-align: left;
                padding: 0;
                margin-left: 5%;
            }
        }

        footer{
            background-color: #dde2ec;
            .w-footer{
                width: calc( 100% - 4rem);
                margin: 0 auto;
                padding: 3rem 0;
                >p{
                    margin: 0;
                    font-weight: 600;
                    font-size: 19px;
                    margin-bottom: 1.5rem;
                }
            }
            p{
                font-weight: 600;
            }
            .shared-list{
                display: flex;
                justify-content: flex-start;
                li{
                    margin-right: 1.5rem;
                    a,button{
                        display: inline-block;
                        padding: .5rem;
                        border-radius: 3px;
                        background-color: #747474;
                        transition: all .3s ease;
                        &:hover, &.active{
                            background-color: #ff9100;
                        }
                    }
                    svg{
                        width: 30px;
                        display: block;
                        margin: 0 auto;
                    }
                }
            }
            form{
                margin-top: 2rem;
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                position: relative;
                input{
                    border: none;
                    appearance: none;
                    background: none;
                    border-bottom: 1px solid gray;
                    height: 40px;
                    flex-grow: 1;
                    font-size: 15px;
                    font-weight: 300;
                    width: 100%;
                    &:hover, &:active, &:visited{
                        border-color: #ff9100;
                    }
                }
                button{
                    appearance: none;
                    background: none;
                    color: #ff9100;
                    font-weight: 600;
                    font-size: 15px;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    height: 40px;
                }
            }

            .queries{
                margin: 3rem 0;
                p{
                    font-size: 18px;
                    margin: 0 0 .5rem;
                }
                a{
                    font-size: 15px;
                    color: #246dc7;
                    font-weight: 600;
                    &.email{
                        color: #4e4f54;
                        font-weight: 300;
                        text-decoration: none;
                    }
                }
            }
            .w-typ{
                margin-bottom: 2rem;
                p{
                    font-weight: 300;
                }
                a,b{
                    font-size: 13.45px;
                    color: #246dc7;
                    font-weight: 600;
                    text-decoration: underline;
                }
            }
        }

        .c-loading{
            position: absolute;
            height: 100%;
            width: 100%;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            display: none;
            padding-top: 5rem;
            z-index: 99;
            .progress-content__box{
                margin: auto;
                width: 100%;
                background: #eff2f7;
            }
            .spinner-loader{
                margin: 2rem auto 0 auto;

            }
            p{
                color: white;
                width: 100%;
                margin: 1rem auto 3rem auto;
                padding: 0;
                font-weight: 400;
                color: #072954;
            }
            &.active{
                display: flex;
            }
        }

        [role="progressbar"] {
            position: relative;
            padding: 0;
            width: 100px;
            height: 25px;
            list-style: none;
          }
          [role="progressbar"] li {
            display: block;
            position: absolute;
            right: 0;
            width: 3px;
            height: 25px;
            border-radius: 1px;
            -webkit-transform-origin: 50% 100%;
                    transform-origin: 50% 100%;
                    background: linear-gradient(to right, #072954 0%, #131229);
            -webkit-animation: bar linear 2.8s infinite;
                    animation: bar linear 2.8s infinite;
            will-change: transform;
          }
          [role="progressbar"] li:nth-child(1) {
            -webkit-transform: translateX(0) rotate(0deg);
                    transform: translateX(0) rotate(0deg);
            opacity: 0.2;
          }
          [role="progressbar"] li:nth-child(2) {
            -webkit-transform: translateX(-15px) rotate(0deg);
                    transform: translateX(-15px) rotate(0deg);
            -webkit-animation-delay: -0.4s;
                    animation-delay: -0.4s;
          }
          [role="progressbar"] li:nth-child(3) {
            -webkit-transform: translateX(-30px) rotate(0deg);
                    transform: translateX(-30px) rotate(0deg);
            -webkit-animation-delay: -0.8s;
                    animation-delay: -0.8s;
          }
          [role="progressbar"] li:nth-child(4) {
            -webkit-transform: translateX(-45px) rotate(10deg);
                    transform: translateX(-45px) rotate(10deg);
            -webkit-animation-delay: -1.2s;
                    animation-delay: -1.2s;
          }
          [role="progressbar"] li:nth-child(5) {
            -webkit-transform: translateX(-60px) rotate(40deg);
                    transform: translateX(-60px) rotate(40deg);
            -webkit-animation-delay: -1.6s;
                    animation-delay: -1.6s;
          }
          [role="progressbar"] li:nth-child(6) {
            -webkit-transform: translateX(-75px) rotate(62deg);
                    transform: translateX(-75px) rotate(62deg);
            -webkit-animation-delay: -2s;
                    animation-delay: -2s;
          }
          [role="progressbar"] li:nth-child(7) {
            -webkit-transform: translateX(-90px) rotate(72deg);
                    transform: translateX(-90px) rotate(72deg);
            -webkit-animation-delay: -2.4s;
                    animation-delay: -2.4s;
          }

          @-webkit-keyframes bar {
            0% {
              -webkit-transform: translateX(0) rotate(0deg);
                      transform: translateX(0) rotate(0deg);
              opacity: 0;
            }
            14.28% {
              -webkit-transform: translateX(-15px) rotate(0deg);
                      transform: translateX(-15px) rotate(0deg);
              opacity: 1;
            }
            28.56% {
              -webkit-transform: translateX(-30px) rotate(0deg);
                      transform: translateX(-30px) rotate(0deg);
              opacity: 1;
            }
            37.12% {
              -webkit-transform: translateX(-39px) rotate(0deg);
                      transform: translateX(-39px) rotate(0deg);
              opacity: 1;
            }
            42.84% {
              -webkit-transform: translateX(-45px) rotate(10deg);
                      transform: translateX(-45px) rotate(10deg);
              opacity: 1;
            }
            57.12% {
              -webkit-transform: translateX(-60px) rotate(40deg);
                      transform: translateX(-60px) rotate(40deg);
              opacity: 1;
            }
            71.4% {
              -webkit-transform: translateX(-75px) rotate(62deg);
                      transform: translateX(-75px) rotate(62deg);
              opacity: 1;
            }
            85.68% {
              -webkit-transform: translateX(-90px) rotate(72deg);
                      transform: translateX(-90px) rotate(72deg);
              opacity: 1;
            }
            100% {
              -webkit-transform: translateX(-105px) rotate(74deg);
                      transform: translateX(-105px) rotate(74deg);
              opacity: 0;
            }
          }

          @keyframes bar {
            0% {
              -webkit-transform: translateX(0) rotate(0deg);
                      transform: translateX(0) rotate(0deg);
              opacity: 0;
            }
            14.28% {
              -webkit-transform: translateX(-15px) rotate(0deg);
                      transform: translateX(-15px) rotate(0deg);
              opacity: 1;
            }
            28.56% {
              -webkit-transform: translateX(-30px) rotate(0deg);
                      transform: translateX(-30px) rotate(0deg);
              opacity: 1;
            }
            37.12% {
              -webkit-transform: translateX(-39px) rotate(0deg);
                      transform: translateX(-39px) rotate(0deg);
              opacity: 1;
            }
            42.84% {
              -webkit-transform: translateX(-45px) rotate(10deg);
                      transform: translateX(-45px) rotate(10deg);
              opacity: 1;
            }
            57.12% {
              -webkit-transform: translateX(-60px) rotate(40deg);
                      transform: translateX(-60px) rotate(40deg);
              opacity: 1;
            }
            71.4% {
              -webkit-transform: translateX(-75px) rotate(62deg);
                      transform: translateX(-75px) rotate(62deg);
              opacity: 1;
            }
            85.68% {
              -webkit-transform: translateX(-90px) rotate(72deg);
                      transform: translateX(-90px) rotate(72deg);
              opacity: 1;
            }
            100% {
              -webkit-transform: translateX(-105px) rotate(74deg);
                      transform: translateX(-105px) rotate(74deg);
              opacity: 0;
            }
          }


          [role="progressbar"] a {
            display: inline-block;
            color: #072954;
            text-decoration: none;
            border-bottom: 1px dashed rgba(255, 255, 255, 0.3);
            -webkit-transition: border-color ease-out 0.15s;
            transition: border-color ease-out 0.15s;
          }
          [role="progressbar"] a:focus, [role="progressbar"] a:hover {
            border-bottom-color: #072954;
          }

          [role="progressbar"] p {
            margin-top: 40px;
            color: #072954;
            font-size: 14px;
            font-family: Avenir Next, Helvetica Neue, Helvetica, sans-serif;
          }

          [role="progressbar"] {
            margin: 0 auto;
          }

        .spin {
            animation-name: spin;
            animation-duration: 5000ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear
        }

        @keyframes spin {
            from {
                transform:rotate(0deg);
            }
            to {
                transform:rotate(360deg);
            }
        }

        @media screen and (min-width: 768px){
            header{
                .w{
                    max-width: 1024px;
                }
            }
            .list-payment-data{
                display: flex; //ie
                max-width: 1024px;
                display: grid; // all
                grid-template-columns: repeat(auto-fit,minmax(280px, 1fr));
                grid-gap: 1rem;
                li{
                    //width: 33.333%; //ie
                    border-bottom: 0;
                    margin-bottom: 0;
                    padding-bottom: 0;
                    //border-right: 1px solid #c1cad6;


                    &:last-child{
                        border-right: 0;
                    }
                }
            }
            .tabs-head{
                max-width: 1024px;
            }
            .tabs-container{
                h3{
                    padding: 4rem 0 1.5rem 0;
                }
                .subtitle{
                    padding: .5rem .5rem 1.5rem .5rem;
                    font-size: 25px;
                }
                .type-pay{
                    font-size: 22px;
                    padding: 0 0 1.5rem;
                }
            }
            .wrap-slider{
                height: 140px;
            }

            .list-steps-1{
                max-width: 960px;
                display: flex; //ie
                width: calc( 100% - 4rem);
                margin: 0 auto 1rem auto;
                justify-content: space-between;

                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
                grid-template-rows: repeat(auto-fit, minmax(100px, 1fr));
                grid-gap: .5rem;
                grid-auto-flow: row dense;

                li{

                    p{
                        max-width: 350px;
                    }
                    &:nth-child(1){
                        grid-column: 1;
                        grid-row: 1;
                    }
                    &:nth-child(2){
                        grid-column: 2;
                        grid-row: 1;
                    }
                    &:nth-child(3){
                        grid-column: 3;
                        grid-row: 1;
                    }
                }
                &.paymentAgentTop{
                    max-width: 768px;
                    justify-content: space-between;
                }
                &.paymentAgent{
                    flex-wrap: wrap;
                    li{

                        &.item-payment-agent{
                            width: 100%;
                            grid-column: span 3;
                            grid-row:  2;
                        }
                    }
                }
            }

            .list-steps-0{
                max-width: 960px;
                display: flex; //ie
                width: calc( 100% - 4rem);
                margin: 0 auto 1rem auto;
                justify-content: space-between;

                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
                grid-template-rows: repeat(auto-fit, minmax(100px, 1fr));
                grid-gap: .5rem;
                grid-auto-flow: row dense;

                li{

                    p{
                        max-width: 350px;
                    }
                    &:nth-child(1){
                        grid-column: 1;
                        grid-row: 1;
                    }
                    &:nth-child(2){
                        grid-column: 2;
                        grid-row: 1;
                    }
                    &:nth-child(3){
                        grid-column: 3;
                        grid-row: 1;
                    }
                }
                &.paymentAgentTop{
                    max-width: 768px;
                    justify-content: space-between;
                }
                &.paymentAgent{
                    flex-wrap: wrap;
                    li{

                        &.item-payment-agent{
                            width: 100%;
                            grid-column: span 3;
                            grid-row:  2;
                        }
                    }
                }
            }

            .info{
                max-width: 1024px;
            }
            .btn{
                width: 380px;
            }





            footer{
                .w-footer{
                    max-width: 1024px;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;

                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
                    grid-gap: 2rem;
                }
                .w-footer-l{
                    order: 1;
                    //width: 40%;
                    p{
                        margin: 0 0 1rem;
                        font-size: 18px;
                    }
                }
                .w-footer-r{
                    display: grid;
                    grid-gap: .5rem;
                }
                .queries{
                    //margin: 0 0 4rem 0;
                    margin: 0 0 1rem 0;
                    p{
                        margin: 0 0 1rem;
                    }
                }
                .w-typ{
                    margin-bottom: 0;
                    p{
                        margin: 0;
                    }
                    a,b{
                        font-size: 15px;
                    }
                }
            }
        }

    }

}
